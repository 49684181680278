.modalBox {
    width: 100%;
    border: 2px solid gray;
    border-radius: 15px;
    margin-top: 75px !important;
}
.mainModalDiv {
    margin-top: 25px !important;
}
.commentBox1 {
    width: 100%;
    border: 1px dotted gray;
    border-radius: 5px;
}
.commentBoxReply {
    margin: 10px;
    float: right;
    width: 90%;
}
.commentDate {
    display: flex;
    justify-content: center;
    white-space: nowrap;
}
.commentContent {

}
.commentPhoto {

}
.commentUsername {

}

.replyButton {
    margin-left: auto;
}

@media only screen and (max-width: 600px) {
    .modalBox {
        width: 85%;
        border: 2px solid gray;
        border-radius: 15px;
        margin-top: 75px !important;
    }
    .mainModalDiv {
        margin-top: 25px !important;
    }
    .commentBox {
        margin: auto;
        width: 100%;
        border: 1px solid gray;
        border-radius: 5px;
    }
    .commentBoxReply {
        margin: 10px;
        float: right;
        width: 100%;
    }
    .commentDate {
        display: none;
    }
    .commentContent {
    }
    .commentPhoto {
    }
    .commentUsername {
        display: none;
    }

    .replyButton {
        float: right;
    }
}

.imageProfileSquare {
    border: 2px solid black;
    border-radius: 20px;
}

.alignRightText {
    text-align: right;
}

.customOverlay {
    background: rgba(230, 230, 230, 0.5) !important;
    opacity: 5%;
  }

.requestItem {
    height: 75px;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
}

.requestItemData {
    display: flex;
    flex-direction: row;
}


table.fixed { table-layout:fixed; }
table.fixed td { overflow: hidden; }

.fileDrop {
    background: #f5f5f5;
    border: 1px dashed #c2c2c2;
    border-radius: 3px;
    text-align: center;
    padding: 36px;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 600;
  }

  .fileDrop:hover {
    background: rgba(50, 50, 50, 0.122);
    border: 1px dashed #333;
  }

.commentBox {
    margin: auto;
    width: 80%;
    border: 1px solid black;
    border-radius: 5px;
}

.videoOnboarding {
    margin: auto;
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.fixed-width-draggable {
    width: auto; /* Adjust the width to your desired fixed width */
    /* Add any other necessary styles here */
  }